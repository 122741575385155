import React, { useState } from 'react'
import { IUser } from '../interfaces/IUser'
import { _users } from '../api/users'
import { api } from '../services/api'
import { useEffect } from 'react'
import { firebaseConnect } from '../services/firebase'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'

interface IAuthContext {
  signIn(email: string, password: string): void;
  user?: IUser | null;
  signOut(): void;
}

export const localStoragedUser = "@SouthProdPromoter-user"

export const AuthContext = React.createContext({} as IAuthContext)

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    firebaseConnect()

    const userString = localStorage.getItem(localStoragedUser)

    if (userString && JSON.parse(userString) !== "{}") {
      const _user = JSON.parse(userString)
      api.defaults.headers.authorization = `Bearer ${_user?.token}`
      setUser(_user)
    }

    api.interceptors.response.use(response => response, error => {
      console.log({error})
      handleSignOut()
      return Promise.reject(error)
    })
  }, [])

  const signIn = async (email: string, password: string) => {
    try {
      const data = await signInWithEmailAndPassword(getAuth(), email, password)

      api.defaults.headers.authorization = `Bearer ${(data.user as any).accessToken}`

      const { data: { user: _user } } = await _users.auth()

      const authenticatedUser = {
        id: _user.id,
        name: _user.name,
        phoneNumber: _user.phoneNumber,
        email: _user.id,
        instagramAccount: _user.instagramAccount,
        created_at: _user.createdAt,
        updated_at: _user.updatedAt,
        token: (data.user as any).accessToken
      }
      localStorage.setItem(localStoragedUser, JSON.stringify(authenticatedUser))

      setUser(authenticatedUser)

    } catch (err) {
      console.log({err})
      await handleSignOut()
      alert(`Email/senha incorretos`)
    }
  }

  const handleSignOut = async () => {
    await signOut(getAuth())
    localStorage.removeItem(localStoragedUser)
    setUser(null)
    api.defaults.headers.authorization = undefined as any
  }

  return (
    <AuthContext.Provider value={{
      signIn,
      signOut: handleSignOut,
      user,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)