import React from "react";
import * as C from '@chakra-ui/react'
import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { Logo } from "./Logo";
import { MenuDrawer } from "./MenuDrawer";
import { useNavigate } from "react-router-dom";

export function Layout({ children }: React.PropsWithChildren) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <C.Flex flexDir="column" w="100vw" overflowX="hidden">
      <C.Flex as="header" w="100%" justify="space-between" align="start" px="2" py="4">
        <Logo h="80px" onClick={() => navigate(`/`)} cursor="pointer" />
        <C.Icon onClick={() => setMenuIsOpen(true)} cursor="pointer" w="50px" as={FiMenu} fontSize="32" />
      </C.Flex>

      <MenuDrawer isOpen={menuIsOpen} setIsOpen={setMenuIsOpen} />

      <C.Flex flexDir="column" w="100%">
        {children}
      </C.Flex>
    </C.Flex>
  )
}