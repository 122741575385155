import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCzsxX1GTKFvBLHWzqeoDM8ZKqxoShi38M",
  authDomain: "southprod53.firebaseapp.com",
  projectId: "southprod53",
  storageBucket: "southprod53.appspot.com",
  messagingSenderId: "952953153542",
  appId: "1:952953153542:web:487c93e8f9162977a07b87",
  measurementId: "G-2P33WW0517"
};

// Initialize Firebase
export const firebaseConnect = () => initializeApp(firebaseConfig);