import { api } from "../../services/api"

const getPosts = async () => {
  return await api.get('/posts')
};

const getByID = async (id: string) => {
  return await api.get(`/posts/find/${id}`)
};

const getUserPostByID = async (id: string) => {
  return await api.get(`/userposts/find-by-id/${id}`)
};


const getUserPosts = async (userID: string) => {
  return await api.get(`/userposts/list-by-user/${userID}`)
};

const markAsPublish = async (userpostID: string) => {
  return await api.put(`/userposts/change-status`, {
    id: userpostID,
    status: 'PUBLISHED'
  })
}

export const _posts = {
  getPosts,
  getByID,
  markAsPublish,
  getUserPosts,
  getUserPostByID
}