import * as C from '@chakra-ui/react'
import { useLocation, Link, matchRoutes } from 'react-router-dom'

interface IRequest {
  isOpen: boolean;
  setIsOpen(x: boolean): void
}

const routes = [
  {
    path: '/',
    label: 'Posts'
  },
  {
    path: '/profile',
    label: 'Meu Perfil'
  },
]

export function MenuDrawer({ isOpen, setIsOpen }: IRequest) {
  const location = useLocation()

  const handleClose = () => {
    setIsOpen(false)
  }
  
  return (
    <C.Drawer placement="right" isOpen={isOpen} onClose={handleClose}>
      <C.DrawerOverlay>
        <C.DrawerContent px="4" py="8" bg="bg.900">
          <C.DrawerCloseButton />
          <C.Flex flex="1" flexDir="column">
            <C.Stack>
              {routes.map(route => (
                <C.Link 
                  key={route.label}
                  as={Link} 
                  to={route.path} 
                  display="flex" 
                  alignItems="center"
                  color={matchRoutes([
                      {...route},
                    ], location) ? "primary.50" : "unset"}
                >
                  <C.Text fontSize="xl">{route.label}</C.Text>
                </C.Link>
              ))}
            </C.Stack>
          </C.Flex>
        </C.DrawerContent>
      </C.DrawerOverlay>
    </C.Drawer>
  )
}