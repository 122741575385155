import { api } from "../../services/api"

const readUser = async () => {
  return await api.get('/users')
};

const auth = async () => {
  return await api.post('/users/user-auth')
};

export const _users = {
  readUser,
  auth
}