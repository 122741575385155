import * as C from '@chakra-ui/react'
import { add } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { IPost } from '../interfaces/IPost'
import { formatDate } from '../utils/formatDate'

interface IProps extends IPost {
  status?: string
}

export function PostCard(post: IProps) {
  const navigate = useNavigate();

  return (
    <C.Flex borderColor="primary.300" borderWidth="1px" flexDir="column" maxW="100%" w="100%" bg="bg.900" px="4" pt="8" pb="4" borderRadius="xl">
      <C.Flex align="center" gap="4">
        <C.Text fontSize="2xl" fontWeight="medium">{post.name}</C.Text>
        {post?.status && <C.Tag borderRadius="full" size="sm" color="white" bg={post.status === "PENDING" ? 'primary.300' : 'green'}>{post.status}</C.Tag>}
      </C.Flex>
      <C.Text opacity={0.75}>{formatDate(new Date(post.dateToPublish))} - {formatDate(add(new Date(post.dateToPublish), { days: 1 }))}</C.Text>
      
      <C.Box mt="12" alignSelf="end">
        <C.Button size="sm" onClick={() => navigate(`/post/${post.id}`)} colorScheme="gray">Ver Post</C.Button>
      </C.Box>
    </C.Flex>
  )
}