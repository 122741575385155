import * as C from '@chakra-ui/react'
import { ImageProps } from '@chakra-ui/react'


interface IRequest extends ImageProps {
  h?: string | number
}

export function Logo({ h = "100px", ...props }: IRequest) {
  return (
    <C.Image src="/logo.png" alt="south-admin" h={h} objectFit="contain" {...props} />
  )
}