import * as C from '@chakra-ui/react'
import { useState } from 'react';
import { Logo } from '../components/Logo'
import { useAuth } from '../hooks/useAuth'

export function SignInPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { signIn } = useAuth();

  const handleSubmit = () => {
    signIn(email, password)
  }
  return (
    <C.Flex w="100vw" h="100vh" align="center" justify="center">
      <C.Flex
        flexDir="column"
        align="center"
        justify="center"
        borderRadius="xl"
        pt="4"
        pb="16"
        px="4"
        w={['100vw', "600px"]}
        h={['100vh', "unset"]}
        maxW="100vw"
        bg={['unset', "bg.900"]}
      >
        <C.Text fontSize="lg" opacity={0.7}>Painel do Promoter</C.Text>
        
        <Logo my="4" />

        <C.Stack as="form" spacing="4">
          <C.InputGroup flexDir="column">
            <C.Text as="label" htmlFor="email">Email</C.Text>
            <C.Input onChange={e => setEmail(e.target.value)} id="email" type="email" placeholder="Ex: email@email.com" />
          </C.InputGroup>

          <C.InputGroup flexDir="column">
            <C.Text as="label" htmlFor="pw">Senha</C.Text>
            <C.Input onChange={e => setPassword(e.target.value)} id="pw" type="password" placeholder="Ex: *******" />
          </C.InputGroup>
          
          <C.Box m="8px auto 0">
            <C.Button size="md" px="16" colorScheme="primary" onClick={handleSubmit}>Entrar</C.Button>
          </C.Box>
        </C.Stack>
      </C.Flex>
    </C.Flex>
  )
}