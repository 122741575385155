import * as C from '@chakra-ui/react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { _posts } from '../api/posts'
import { Layout } from '../components/Layout'
import { PostCard } from '../components/PostCard'
import { useAuth } from '../hooks/useAuth'
import { IPost } from '../interfaces/IPost'
import { IUserPost } from '../interfaces/IUserPost'

export function PostsScreen() {
  const [posts, setPosts] = useState<IUserPost[]>([])
  const { user } = useAuth()
  const location = useLocation();

  useEffect(() => {
    _posts.getUserPosts(user?.id as string).then(({ data }) => {
      setPosts(data?.userPost || [])
    }).catch(console.error)
  }, [location.pathname, user?.id])

  return (
    <Layout>
      <C.Flex flexDir="column" w="100%" pb="8">
        <C.Flex align="center" as="header" px="4" mb="8">
          <C.Text fontSize="3xl" fontWeight="semibold">Meus Posts</C.Text>
          {/* <C.Text fontSize="3xl" fontWeight="semibold">Posts {PostFilters[filter]}</C.Text> */}
          
          {/* <C.Icon cursor="pointer" color="primary.300" ml="2" onClick={() => setFilterIsOpen(true)} as={FiFilter} fontSize="32" /> */}
        </C.Flex>

        <C.List px="4" maxW="100vw" spacing="6">
          {posts.map(post => (
            <PostCard {...post.post} status={post.status} id={post.id} key={post?.id} />
          ))}
        </C.List>

        {/* <FilterDrawer isOpen={filterIsOpen} setIsOpen={setFilterIsOpen} filter={filter} setFilter={setFilter} /> */}
      </C.Flex>
    </Layout>
  )
}