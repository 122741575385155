import * as C from '@chakra-ui/react'
import { Layout } from '../components/Layout'
import { useAuth } from '../hooks/useAuth'

export function MyProfileScreen() {
  const { user, signOut } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <Layout>
      <C.Flex flexDir="column" align="center" w="100%" pb="8" px="2">
        <C.Avatar src={user.profilePicURL} size="2xl" borderRadius="full" />
        <C.Text fontSize="4xl">{user.name}</C.Text>
        <C.Text>@{user.instagramAccount}</C.Text>


        <C.Flex mt="6" gap="4">
          <C.Text>email: {user.email}</C.Text>
          <C.Text>telefone: {user.phoneNumber}</C.Text>
        </C.Flex>

        <C.HStack spacing="4">
          {/* <C.Button mt="5vh" colorScheme="primary">Editar Perfil</C.Button> */}
          <C.Button mt="5vh" colorScheme="red" onClick={signOut}>Sair</C.Button>
        </C.HStack>
      </C.Flex>
    </Layout>
  )
}