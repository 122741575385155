import React from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { MyProfileScreen } from "../pages/MyProfile";
import { PostScreen } from "../pages/Post";
import { PostsScreen } from "../pages/Posts";
import { SignInPage } from "../pages/SIgnIn";

const signInRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/signin" Component={SignInPage} />
      <Route path="*" element={<Navigate to="/signin" replace />} />
    </>
  )
)

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" Component={PostsScreen} />
      <Route path="/profile" Component={MyProfileScreen} />
      <Route path="/post/:id" Component={PostScreen} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  )
)

export const Router: React.FC = () => {
  const { user } = useAuth();

  return (
    <RouterProvider router={user?.id ? appRouter : signInRouter} />
  )
}