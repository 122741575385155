import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  colors: {
    primary: {
      50:  '#fa8934',
      300: '#be5f18',
      500: '#be5f18',
      700: '#8a2a04',
      900: '#8a2a04',
    },
    text: {
      900: '#f9f9f9'
    },
    bg: {
      700: '#04151c',
      900: '#020d12'
    }
  },
  styles: {
    global: {
      body: {
        bg: 'radial-gradient(circle, #041a38 0%, #041a38 19%, #020d12 100%)',
        bgAttachment: 'fixed',
        color: 'text.900'
      }
    }
  },
  breakpoints: {
    sm: '800px',
    md: '960px',
  }
})