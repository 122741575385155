import * as C from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import {  useNavigate, useParams } from 'react-router-dom'
import { _posts } from '../api/posts'
import { Layout } from '../components/Layout'
import { useAuth } from '../hooks/useAuth'
import { IPost } from '../interfaces/IPost'
import { IUser } from '../interfaces/IUser'
import { IUserPost } from '../interfaces/IUserPost'
import { clipboard } from '../utils/clipboard'

export function PostScreen() {
  const [published, setPublished] = useState(false);
  const [post, setPost] = useState<IUserPost>({} as IUserPost)
  const [isLoading, setIsLoading] = useState(true)

  const { id } = useParams()
  const { user } = useAuth()
  const navigate = useNavigate()
  const toast = C.useToast();

  const copyToClickboard = () => {
    if (post?.post?.linkURL) {
      clipboard.copy(post?.post?.linkURL)
      console.log(post?.post?.linkURL);
      
      toast({
        title: 'Link copiado com sucesso!',
        status: 'success',
        position: 'top'
      })
    }
  }

  const changeDownloadFileURL = (str: string) => {
    return str;
    const id = str?.split('/d/')?.pop()?.split('/view')[0];

    if (!id) {
      return str
    }

    return `https://drive.google.com/uc?export=download&id=${id}`
  }

  const onSubmit = () => {
    _posts.markAsPublish(post?.id).then(() => toast({
      title: 'Post marcado como publicado com sucesso!',
      status: 'success',
      position: 'top'
    }))
    navigate('/')
  }

  useEffect(() => {
    if (id) {
      _posts.getUserPostByID(id).then(({ data }) => {
        setPost(data.userPost)
      }).catch(console.error).finally(() => setIsLoading(false))
    }
  }, [id])


  if ((!id || !post?.id ) && !isLoading) {
    return <Layout><C.Text>Post não encontrado</C.Text></Layout>
  }

  return (
    <Layout>
      <C.Flex flexDir="column" align="center" w="100%" pb="8" px="2">
        <C.Text fontSize="2xl" textAlign="center" color="primary.50" fontWeight="medium">{post?.post?.name}</C.Text>

        <C.Flex align="center" gap="2">
          <C.Text display={['none', 'unset']}>Data para Publicar:</C.Text>
        </C.Flex> 

        <C.Stack my="8" spacing="4">
          {post?.post?.assetURL && (
            <C.Button as="a" target="_blank" href={changeDownloadFileURL(post?.post?.assetURL)} size={['md', 'lg']} colorScheme="pink">
              Imagem/vídeo
            </C.Button>
          )}

          {post?.post?.linkURL && (
            <C.Button onClick={copyToClickboard} size={['md', 'lg']} colorScheme="pink">
              Copiar Link para adicionar
            </C.Button>
          )}
        </C.Stack>

        <C.Flex my="8" align="center" gap="2">
          <C.Switch colorScheme="green" onChange={e => setPublished(e.target.checked)} id="published" size="md" mb="6px" />
          <C.FormLabel fontSize="md" htmlFor="published">Confirmar publicação do Story</C.FormLabel>
        </C.Flex>

        {published && (
          <C.Button onClick={onSubmit} px="12" colorScheme="primary">Salvar</C.Button>
        )}
      </C.Flex>
    </Layout>
  )
}